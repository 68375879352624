import React, {useEffect, useState} from "react";
import "./versionStyles.css";

import { Dialog, DialogTitle, IconButton, CircularProgress, Button, Typography, Grid } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

export default function VersionViewer(props) {
    const [loading, setLoading] = useState(true);
    const [versions, setVersions] = useState([]);

    const getVersions = () => {
        window.sessionStorage.removeItem('versions');
        window.addEventListener(`version-update`, (e) => {
            setTimeout(() => {
                updateVersionArray(e);
            }, Math.random() * 5000)
        });

        setTimeout(() => {
            setLoading(false);
        }, 5000);
    }

    const updateVersionArray = (version) => {
        let versionArray = window.sessionStorage.getItem('versions');
        if(versionArray == undefined) return;
        try {
            versionArray = JSON.parse(versionArray)
        } catch (error) { return; }

        let versionNames = versions.map((version) => {
            return version.name;
        });

        let temp = [ 
            ...versions,
            ...versionArray.filter(version => versionNames.indexOf(version.name) == -1)
        ];
        setVersions(temp);
    }
    
    const refreshVersions = () => {
        setVersions([]);
        window.sessionStorage.removeItem('versions');
        window.dispatchEvent(new CustomEvent(`version-request`, {
                bubbles: true,
                cancelable: true,
                detail: {}
            }
        ))
    }

    const closeDialog = () => {
        props.setVOpen(false);
    }

    useEffect(() => {
        setLoading(true);
        getVersions();
    }, []);

    return (
        <Dialog open={props.vOpen} onClose={closeDialog} fullWidth maxWidth="md">
            <div className="versionContainer">
                <div className="versionHeader">
                    <DialogTitle className="versionTitle">MYBE Version</DialogTitle>
                    <IconButton onClick={() => {props.setVOpen(false)}}><CancelIcon style={{ color: "white" }}/></IconButton>
                </div>
                <div className="versionBody">
                    {
                        loading ? (
                            <div style={{ textAlign: "center" }}>
                                <CircularProgress />
                                <Typography>Loading Versions...</Typography>
                            </div>
                        ) : (
                            <>
                                <div style={{ width: "100%", height: "10vh" }}>
                                    <Button 
                                        variant="contained" 
                                        style={{ backgroundColor: "yellow", color: "black" }}
                                        onClick={() => {refreshVersions()}}
                                        fullWidth
                                    >
                                        Refresh Versions
                                    </Button>
                                </div>
                                <Grid container spacing={3}>
                                {
                                    window.localStorage.getItem("grant") == undefined ||
                                        !parseInt(window.localStorage.getItem("grant")) ? (
                                            <Typography
                                                style={{
                                                    padding: "10px",
                                                    textAlign: "center"
                                                }}
                                            >
                                                You have not accepted Cookies on MYBE applications. Version Checker requries cookies in order to track which version you are using. <br/>
                                                <Button
                                                    onClick={() => {window.localStorage.setItem("grant", 1); window.location.reload()}}
                                                    variant="contained"
                                                    style={{ backgroundColor: "green", color: "white" }}
                                                >
                                                    Accept Cookies
                                                </Button>
                                            </Typography>
                                        ) : (
                                            <>
                                                {
                                                    versions.map((version, key) => {
                                                        return (
                                                            <Grid item key={key} xs={12} md={6} style={{ textAlign: "center" }}>
                                                                <hr/>
                                                                <Typography variant="h6">
                                                                    {version.name} - V{version.number}
                                                                </Typography>
                                                                <Typography>{version.message}</Typography>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </>
                                        )
                                }
                                </Grid>
                            </>
                        )
                    }
                </div>
            </div>
        </Dialog>
    );
}