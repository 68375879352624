import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import LogCallPopup from "./common/LogCallPopup";
import AppButton from "./common/AppButton";
import VersionViewer from "./versionViewer";

import { IconButton } from "@mui/material";
import HelpIcon from '@mui/icons-material/Help';

export default function Layout(props) {
  const context = props.context;

  const [open, setOpen] = useState(false);
  const [vOpen, setVOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className="container"
      style={{
        background: context.theme.sidebar.background,
        padding: 20,
        wdith: "100%",
        height: "100vh",
        overflow:
          context.app_uuid === "76635ee3-bb89-4ff4-86aa-b4dfae0887b4" ||
          context.app_uuid === "ff0eaf73-93b8-44f0-8219-22cacda89850" ||
          context.app_uuid === "5380da56-1f21-453d-8c60-0c51372444ef"
            ? "scroll"
            : "hidden",
      }}
    >
      <VersionViewer vOpen={vOpen} setVOpen={setVOpen} context={context} />
      {open && (
        <LogCallPopup open={open} cancel={handleClose} setOpen={setOpen} />
      )}
      <div style={{ display: "flex", flexDirection: "row", margin: 20 }}>
        <div style={{ flex: 1 }}>
          <img
            className="profile"
            src={
              typeof context.profile != "undefined"
                ? context.profile
                : "https://www.gravatar.com/avatar/94d093eda664addd6e450d7e9881bcad?s=32&d=identicon&r=PG"
            }
            alt="Profile"
          />
        </div>
        <div className="user-content" style={{ flex: 2 }}>
          <div
            className="username"
            style={{ color: context.theme.sidebar.font }}
          >
            {context.name}
          </div>
          {context.group_name && (
            <div className="group-name">{context.group_name}</div>
          )}
          <div className="role" style={{ color: context.theme.sidebar.font }}>
            {context.role_name}
            <IconButton onClick={() => { setVOpen(true) }}>
              <HelpIcon style={{ fontSize: "10pt", color: "white" }} />
            </IconButton>
          </div>
        </div>
      </div>

      <div className="divider" />
      {(context.app_uuid === "76635ee3-bb89-4ff4-86aa-b4dfae0887b4" ||
        context.app_uuid === "ff0eaf73-93b8-44f0-8219-22cacda89850" ||
        context.app_uuid === "5380da56-1f21-453d-8c60-0c51372444ef") && (
        <>
          <AppButton
            onClick={() => setOpen(true)}
            btnIcon="call"
            text="log call"
            style={{
              background: "white",
              color: context.theme.sidebar.background,
              marginLeft: "auto",
              marginRight: "auto",
              display: "flex",
              marginTop: 10,
              marginBottom: 10,
            }}
          />
          <div className="divider" style={{ marginTop: 5, marginBottom: 20 }} />
        </>
      )}

      <ul
        style={{
          overflowY: "scroll",
          height: "100%",
          marginRight: "-45px",
          paddingRight: 20,
        }}
      >
        {Object.keys(context.access).map((item, key) => {
          return (
            <li key={key}>
              <NavLink activeClassName="nav-active" to={`/${item}/index`}>
                <div
                  className="nav"
                  style={{ color: context.theme.sidebar.font }}
                >
                  <Icon>{context.access[item].options.icon_name}</Icon>
                  <span>
                    {context.access[item].options.side_menu_name
                      ? context.access[item].options.side_menu_name
                      : item}
                  </span>
                </div>
              </NavLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
